import React, { RefObject } from "react";
import { navigate } from "gatsby";

import "./pagination.css";

interface Props {
    pageCount: number;
    currentPage?: number;
    activeCategory?: string;
    scrollElement: RefObject<HTMLDivElement>;
}

const Pagination = ({ pageCount = 1, currentPage = 1, activeCategory = "", scrollElement }: Props) => {
    function setScrollPositionToCategorySelector(path: string) {
        return () => {
            if (scrollElement?.current) {
                const scrollPosition = scrollElement?.current.getBoundingClientRect().top + window.pageYOffset - 48;
                sessionStorage.setItem("scrollPosition", `${scrollPosition}`);
                navigate(path);
            }
        };
    }

    return (
        <div className="pagination">
            <button
                className="navigate"
                disabled={currentPage === 1}
                onClick={setScrollPositionToCategorySelector(`/${activeCategory ? `${activeCategory}/` : ""}${currentPage > 2 ? `page/${currentPage - 1}` : ""}`)}>
                ← Previous
            </button>
            {
                Array.from({ length: pageCount }).map((_, index) => (
                    <button
                        key={`/${activeCategory ? `${activeCategory}/` : ""}${index + 1}`}
                        onClick={setScrollPositionToCategorySelector(`/${activeCategory ? `${activeCategory}/` : ""}${index === 0 ? "" : `page/${index + 1}`}`)}
                        className={`navigateButton ${currentPage === index + 1 ? "active" : ""}`}>
                        {index + 1}
                    </button>
                ))
            }
            <button
                className="navigate"
                disabled={currentPage === pageCount}
                onClick={setScrollPositionToCategorySelector(`/${activeCategory ? `${activeCategory}/` : ""}page/${currentPage + 1}`)}>
                Next →
            </button>
        </div>
    );
};

export default Pagination;
