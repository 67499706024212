import React from "react";

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

function TwitterIcon({ width = "24", height = "24", color = "#08a0e9" }: Props) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 19">
            <g fill={color} fillRule="nonzero">
                <path d="M7.53 19c9.063 0 14.018-7.312 14.018-13.648 0-.21 0-.418-.01-.617.96-.676 1.798-1.522 2.462-2.487-.879.378-1.829.637-2.83.756 1.022-.597 1.798-1.532 2.166-2.656-.95.547-2.003.945-3.127 1.164C19.31.577 18.033 0 16.613 0c-2.718 0-4.925 2.149-4.925 4.795 0 .378.041.746.133 1.094C7.724 5.69 4.097 3.78 1.665.875c-.419.707-.664 1.532-.664 2.408 0 1.661.869 3.133 2.197 3.989-.807-.02-1.563-.239-2.227-.597v.06c0 2.327 1.696 4.257 3.954 4.705-.41.11-.848.169-1.298.169-.317 0-.623-.03-.93-.09.624 1.91 2.442 3.293 4.598 3.333-1.686 1.283-3.811 2.049-6.12 2.049-.398 0-.787-.02-1.175-.07C2.156 18.204 4.75 19 7.53 19z" transform="translate(-412 -806) translate(412 804) translate(0 2)"/>
            </g>
        </svg>
    );
}

export default TwitterIcon;
