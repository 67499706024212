import React from "react";

import "./authorLine.css";

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface AuthorData {
    username: string;
    email: string;
    color: string;
}

interface Props {
    image: string;
    name: string;
    publishedAt: string;
    author?: AuthorData;
    oneLine?: boolean;
}

const AuthorLine = ({ image, name, publishedAt }: Props) => {
    const date = new Date(publishedAt);

    return (
        <div className="authorLine">
            <img className="image" src={image}/>
            <span className="name">{name}</span>
            <span className="date">{`${MONTHS[date.getMonth()]} ${date.getFullYear()}`}</span>
        </div>
    );
};

export default AuthorLine;
