import React from "react";

import FacebookIcon from "../FacebookIcon";
import TwitterIcon from "../TwitterIcon";
import LinkedinIcon from "../LinkedinIcon";

import "./socialLinks.css";
import InstagramIcon from "../InstagramIcon";

interface Props {
    twitter?: string;
    facebook?: string;
    linkedin?: string;
    instagram?: string;
    greyedOut?: boolean;
}

const SocialLinks = ({
    twitter,
    facebook,
    linkedin,
    instagram,
    greyedOut
}: Props) => (
    <div className="socialLinks">
        {twitter && <a href={twitter} target="_blank" rel="noopener"><TwitterIcon color={greyedOut ? "#979197" : undefined}/></a>}
        {linkedin && <a href={linkedin} target="_blank" rel="noopener"><LinkedinIcon/></a>}
        {facebook && <a href={facebook} target="_blank" rel="noopener"><FacebookIcon color={greyedOut ? "#979197" : undefined}/></a>}
        {instagram && <a href={instagram} target="_blank" rel="noopener"><InstagramIcon color={greyedOut ? "#979197" : undefined}/></a>}
    </div>
);

export default SocialLinks;
