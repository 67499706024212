import React from "react";

import "./horizontalCard.css";

interface Props {
    image: string;
    children: JSX.Element | JSX.Element[];
}

const HorizontalCard = ({ image, children }: Props) => (
    <div className="horizontalCard noSelect">
        <div className="info">
            {children}
        </div>
        <div className="cardImageContainer">
            <div className="cardMainImage" style={{ backgroundImage: `url(${image})` }}/>
        </div>
    </div>
);

export default HorizontalCard;
