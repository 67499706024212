import React from "react";
import Card from "../Card";
import {
    SanityInfoCard
} from "../../types/types";

import "./infoCards.css";

interface Props {
    infoCards: SanityInfoCard[];
}

const InfoCards = ({ infoCards }: Props) => (
    <div className="infoCards">
        {
            infoCards.map(({
                title,
                description,
                mainImage,
                redirectLabel,
                redirectUrl
            }) => (
                <a key={title} href={redirectUrl}>
                    <Card
                        image={mainImage?.asset.gatsbyImageData.images.fallback.src}>
                        <div className="infoCard">
                            <h3 className="infoCardTitle">{title}</h3>
                            <span className="blogText infoCardDescription">{description}</span>
                            <span className="infoCardLink">
                                {redirectLabel} →
                            </span>
                        </div>
                    </Card>
                </a>
            ))
        }
    </div>
);

export default InfoCards;
