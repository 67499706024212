import React from "react";

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

function FacebookIcon({ width = "24", height = "24", color = "#2867b2" }: Props) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24">
            <g fill={color} fillRule="nonzero">
                <path d="M11.995 0C18.62 0 23.99 5.37 23.99 11.995c0 6.625-5.37 11.995-11.995 11.995C5.37 23.99 0 18.62 0 11.995 0 5.37 5.37 0 11.995 0zm3.902 3.682h-2.659c-1.577 0-3.33.663-3.33 2.949.007.796 0 1.56 0 2.418H8.082v2.902h1.88v8.357h3.457v-8.412h2.28l.207-2.856H13.36v-.135l.001-.094V8.57l.001-.139v-.718c0-.128 0-.238-.002-.312 0-.85.833-.854.978-.852l.954.001h.605V3.681z" transform="translate(-484 -804) translate(412 804) translate(72.009 .002)"/>
            </g>
        </svg>
    );
}

export default FacebookIcon;
