import React from "react";

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

function InstagramIcon({ width = "24", height = "24", color }: Props) {
    return color ? (
        <svg width={width} height={height} viewBox="0 0 24 24">
            <g fill={color} fillRule="evenodd">
                <g fillRule="nonzero" transform="translate(-642 -4077)">
                    <path d="M8.001 12c0-2.21 1.79-4 4-4 2.209 0 4 1.79 4 4s-1.791 4-4 4c-2.21 0-4-1.79-4-4m-2.163 0c0 3.403 2.76 6.162 6.162 6.162 3.404 0 6.163-2.759 6.163-6.162S15.403 5.838 12 5.838c-3.403 0-6.162 2.759-6.162 6.162m11.128-6.406c0 .795.645 1.44 1.44 1.44.795 0 1.44-.644 1.44-1.439s-.644-1.44-1.439-1.44-1.44.644-1.44 1.439M7.151 21.768c-1.17-.054-1.806-.248-2.228-.413-.56-.218-.96-.478-1.38-.898-.42-.42-.681-.819-.898-1.379-.165-.422-.36-1.059-.413-2.228-.058-1.265-.07-1.645-.07-4.85 0-3.204.013-3.583.07-4.85.053-1.17.25-1.804.413-2.228.218-.56.478-.96.897-1.38.42-.42.82-.68 1.38-.898.423-.164 1.06-.36 2.23-.412 1.264-.059 1.644-.07 4.847-.07 3.204 0 3.584.012 4.85.07 1.17.053 1.805.25 2.229.412.56.217.96.478 1.38.898.42.42.68.82.898 1.38.164.423.36 1.059.412 2.229.059 1.266.07 1.645.07 4.85 0 3.204-.011 3.583-.07 4.849-.053 1.17-.249 1.805-.412 2.228-.218.56-.478.96-.898 1.38-.42.419-.82.679-1.38.897-.423.165-1.059.36-2.229.413-1.265.058-1.645.07-4.85.07-3.204 0-3.583-.012-4.848-.07M7.052.073C5.776.13 4.903.333 4.14.63c-.79.306-1.458.717-2.126 1.384C1.346 2.681.936 3.35.63 4.14c-.297.763-.5 1.635-.557 2.913C.013 8.333 0 8.74 0 12c0 3.259.014 3.668.073 4.947.058 1.278.26 2.15.557 2.913.306.79.716 1.46 1.384 2.126.668.666 1.337 1.077 2.126 1.384.764.297 1.635.5 2.913.557C8.333 23.986 8.742 24 12 24c3.26 0 3.668-.014 4.948-.073 1.278-.058 2.15-.26 2.913-.557.789-.307 1.458-.717 2.126-1.384.668-.667 1.077-1.337 1.384-2.126.297-.763.5-1.635.557-2.913.058-1.28.072-1.688.072-4.947 0-3.259-.014-3.668-.072-4.947-.058-1.278-.26-2.15-.557-2.913-.307-.79-.717-1.458-1.384-2.126C21.32 1.346 20.65.936 19.862.63c-.764-.297-1.636-.5-2.913-.557C15.669.014 15.26 0 12.001 0 8.742 0 8.333.014 7.053.073" transform="translate(128 4077) translate(514 .002)"/>
                </g>
            </g>
        </svg>
    ) : (
        <svg width={width} height={height} viewBox="0 0 24 24">

            <defs>
                <radialGradient id="gna7a69t0a" cx="13.286%" cy="100.472%" r="130.547%" fx="13.286%" fy="100.472%">
                    <stop offset="9%" stopColor="#FA8F21"/>
                    <stop offset="78%" stopColor="#D82D7E"/>
                </radialGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g fill="url(#gna7a69t0a)" fillRule="nonzero" transform="translate(-642 -4077)">
                    <path d="M8.001 12c0-2.21 1.79-4 4-4 2.209 0 4 1.79 4 4s-1.791 4-4 4c-2.21 0-4-1.79-4-4m-2.163 0c0 3.403 2.76 6.162 6.162 6.162 3.404 0 6.163-2.759 6.163-6.162S15.403 5.838 12 5.838c-3.403 0-6.162 2.759-6.162 6.162m11.128-6.406c0 .795.645 1.44 1.44 1.44.795 0 1.44-.644 1.44-1.439s-.644-1.44-1.439-1.44-1.44.644-1.44 1.439M7.151 21.768c-1.17-.054-1.806-.248-2.228-.413-.56-.218-.96-.478-1.38-.898-.42-.42-.681-.819-.898-1.379-.165-.422-.36-1.059-.413-2.228-.058-1.265-.07-1.645-.07-4.85 0-3.204.013-3.583.07-4.85.053-1.17.25-1.804.413-2.228.218-.56.478-.96.897-1.38.42-.42.82-.68 1.38-.898.423-.164 1.06-.36 2.23-.412 1.264-.059 1.644-.07 4.847-.07 3.204 0 3.584.012 4.85.07 1.17.053 1.805.25 2.229.412.56.217.96.478 1.38.898.42.42.68.82.898 1.38.164.423.36 1.059.412 2.229.059 1.266.07 1.645.07 4.85 0 3.204-.011 3.583-.07 4.849-.053 1.17-.249 1.805-.412 2.228-.218.56-.478.96-.898 1.38-.42.419-.82.679-1.38.897-.423.165-1.059.36-2.229.413-1.265.058-1.645.07-4.85.07-3.204 0-3.583-.012-4.848-.07M7.052.073C5.776.13 4.903.333 4.14.63c-.79.306-1.458.717-2.126 1.384C1.346 2.681.936 3.35.63 4.14c-.297.763-.5 1.635-.557 2.913C.013 8.333 0 8.74 0 12c0 3.259.014 3.668.073 4.947.058 1.278.26 2.15.557 2.913.306.79.716 1.46 1.384 2.126.668.666 1.337 1.077 2.126 1.384.764.297 1.635.5 2.913.557C8.333 23.986 8.742 24 12 24c3.26 0 3.668-.014 4.948-.073 1.278-.058 2.15-.26 2.913-.557.789-.307 1.458-.717 2.126-1.384.668-.667 1.077-1.337 1.384-2.126.297-.763.5-1.635.557-2.913.058-1.28.072-1.688.072-4.947 0-3.259-.014-3.668-.072-4.947-.058-1.278-.26-2.15-.557-2.913-.307-.79-.717-1.458-1.384-2.126C21.32 1.346 20.65.936 19.862.63c-.764-.297-1.636-.5-2.913-.557C15.669.014 15.26 0 12.001 0 8.742 0 8.333.014 7.053.073" transform="translate(128 4077) translate(514 .002)"/>
                </g>
            </g>
        </svg>
    );
}

export default InstagramIcon;
