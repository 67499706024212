import React from "react";
import Tag from "../Tag";

import "./postInfo.css";

interface Props {
  tag: {
    tid: string;
    name: string;
  };
  title: string;
  description: string;
  author: JSX.Element;
}

const PostInfo = ({ tag, title, description, author }: Props) => (
    <div className="postInfo">
        {!!tag && <Tag id={tag.tid} name={tag.name}/>}
        <h3 className="title">{title}</h3>
        <span className="description blogText">{description}</span>
        <div className="postAuthor">
            {author}
        </div>
    </div>
);

export default PostInfo;
