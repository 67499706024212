import React, { ForwardedRef } from "react";
import { navigate } from "gatsby";

import "./categorySelector.css";

interface Category {
    id: string;
    name: string;
}
interface Props {
    categories: Category[];
    activeCategory?: string;
}

const CategorySelector = React.forwardRef(({ categories, activeCategory = "" }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    function setScrollPositionToCategorySelector(categoryId: string) {
        return () => {
            if (ref) {
                const scrollPosition = window.scrollY;
                sessionStorage.setItem("scrollPosition", `${scrollPosition}`);
                navigate(categoryId === activeCategory ? "/" : `/${categoryId}`);
            }
        };
    }

    return (
        <div className="categorySelector" ref={ref}>
            {
                categories.map(category => (
                    <button
                        key={category.id}
                        className={`category ${activeCategory === category.id ? "active" : ""}`}
                        onClick={setScrollPositionToCategorySelector(category.id)}>
                        {category.name}
                    </button>
                ))
            }
        </div>
    );
});

export { Category };
export default CategorySelector;
