import React from "react";
import { navigate } from "gatsby";

import "./tag.css";

interface Props {
  id: string;
  name: string;
}

function getLabelColor(label: string) {
    switch (label) {
        case "life-at-zeplin":
            return "--cornflower-blue";
        case "case-study":
            return "--orange";
        case "feature-launch":
            return "--cornflower-blue"; // TODO to be updated
        default:
            return "--cornflower-blue";
    }
}

const Tag = ({ id, name }: Props) => {
    const labelColor = getLabelColor(id);
    const navigateToTagArchive = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        navigate(`/${id}`);
    };

    return (
        <div
            onClick={navigateToTagArchive}
            className="tag"
            style={{ backgroundColor: `var(${labelColor}-15pc)`, color: `var(${labelColor})` }}>
            {name}
        </div>
    );
};

export default Tag;
