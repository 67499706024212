import React from "react";
import AuthorLine from "../AuthorLine";
import Card from "../Card";
import PostInfo from "../PostInfo";
import { Link } from "gatsby";

import {
    SanityPost
} from "../../types/types";

import "./posts.css";

interface Props {
  posts: SanityPost[];
}

const Posts = ({ posts }: Props) => (
    <div className="posts">
        {
            posts.map(({
                author,
                mainImage,
                tag,
                title,
                description,
                publishedAt,
                slug
            }) => (
                <Link key={slug.current} to={`/${slug.current}`}>
                    <Card
                        image={mainImage?.asset.gatsbyImageData.images.fallback.src}>
                        <PostInfo
                            tag={tag}
                            title={title}
                            description={description}
                            author={author && (
                                <AuthorLine
                                    image={author.image.asset.gatsbyImageData.images.fallback.src}
                                    name={author.name}
                                    publishedAt={publishedAt}/>
                            )}/>
                    </Card>
                </Link>
            ))
        }
    </div>
);

export default Posts;
