import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Author from "../components/Author";
import Posts from "../components/Posts";
import HorizontalCard from "../components/HorizontalCard";
import CategorySelector from "../components/CategorySelector";
import Pagination from "../components/Pagination";
import PostInfo from "../components/PostInfo";
import SocialLinks from "../components/SocialLinks";
import InfoCards from "../components/InfoCards";
import { MixpanelContext, trackMixpanelOnScroll } from "../tracking";
import ScrollMarker from "../components/ScrollMarker";
import UserDataContext from "../userDataContext";

import {
    Props
} from "../types/types";

import classname from "../utils/classname";

import "./dashboard.css";

const query = graphql`
    query($limit: Int!, $skip: Int! = 0, $filter: SanityPostFilterInput) {
        allSanityPost(limit: $limit, skip: $skip, filter: $filter, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                ...DashboardCard
                _id
                publishedAt
            }
        }
        featuredPost: allSanityFeaturedPost(limit: 1) {
            nodes {
                post {
                    ...DashboardCard
                }
            }
        }
        allSanityInfoCard {
            nodes {
                ...InfoCard
            }
        }
    }
`;

const Dashboard = ({ data, pageContext: {
    categories,
    activeCategory,
    totalPageCount,
    currentPage
} }: Props) => {
    const mixpanel = useContext(MixpanelContext);

    const {
        slug: featuredPostSlug,
        mainImage: featuredPostMainImage,
        tag: featuredPostTag,
        title: featuredPostTitle,
        description: featuredPostDescription,
        author: featuredPostAuthor,
        publishedAt: featuredPostPublishedAt
    } = data.featuredPost.nodes.length > 0 ? data.featuredPost?.nodes[0].post : {};

    let posts = data.allSanityPost.nodes;
    if (process.env.NODE_ENV === "production") {
        posts = posts.filter(({ publishedAt }) => Date.parse(publishedAt) < Date.parse(new Date().toString()));
    }
    const infoCards = data.allSanityInfoCard.nodes;
    const [autoScrolling, setAutoScrolling] = React.useState(typeof window !== "undefined" ? !!sessionStorage.getItem("scrollPosition") : false);
    const userData = useContext(UserDataContext);

    useEffect(() => {
        trackMixpanelOnScroll(userData.data);
    }, []);

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition, 10));
            sessionStorage.removeItem("scrollPosition");
            setAutoScrolling(false);
        } else {
            window.scrollTo(0, 0);
        }
        mixpanel.track("Visited landing page", {
            // path: window.location.href
            Path: window.location.pathname
        });
    }, []);
    const categoryRef = React.createRef<HTMLDivElement>();
    const _heroEndMarkerRef = React.useRef<HTMLDivElement>(null);

    return (
        <div className={classname("dashboard", { autoScrolling })}>
            <Layout className="main" scrollMarkerRef={_heroEndMarkerRef}>
                <Seo title="Zeplin Gazette" />
                <h1 className="pageTitle">
                    <span>Zeplin Gazette. </span>
                    <span>Stories for and by product teams.</span>
                </h1>
                {
                    !!featuredPostSlug && !!featuredPostTitle
                        ? (
                            <Link key={featuredPostSlug.current} to={`/${featuredPostSlug.current}`}>
                                <HorizontalCard
                                    image={featuredPostMainImage.asset.gatsbyImageData.images.fallback.src}>
                                    <PostInfo
                                        tag={featuredPostTag}
                                        title={featuredPostTitle}
                                        description={featuredPostDescription}
                                        author={(
                                            <Author
                                                image={featuredPostAuthor.image.asset.gatsbyImageData.images.fallback.src}
                                                name={featuredPostAuthor.name}
                                                publishedAt={featuredPostPublishedAt}
                                                author={{
                                                    username: featuredPostAuthor.username,
                                                    color: featuredPostAuthor.color
                                                }} />
                                        )} />
                                    <ScrollMarker ref={_heroEndMarkerRef} />
                                </HorizontalCard>
                            </Link>
                        )
                        : <></>
                }

                <CategorySelector
                    ref={categoryRef}
                    categories={Array.from(categories)}
                    activeCategory={activeCategory} />
                <Posts posts={posts} />
                <div className="paginationBar">
                    <Pagination
                        scrollElement={categoryRef}
                        pageCount={totalPageCount}
                        currentPage={currentPage}
                        activeCategory={activeCategory} />
                </div>
                <InfoCards infoCards={infoCards} />
                <div className="bottomMessage">
                    <div className="blogText">Zeplin: Deliver on the promise of design. <span className="mediaLinks">Follow us on</span></div>
                    <div className="icons">
                        <SocialLinks
                            twitter="https://twitter.com/zeplin"
                            facebook="https://www.facebook.com/zeplin.io/"
                            instagram="https://www.instagram.com/zeplin.io" />
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export { query };
export default Dashboard;
