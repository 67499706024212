import React from "react";

interface Props {
    width?: string;
    height?: string;
}

function LinkedinIcon({ width = "24", height = "24" }: Props) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24">
            <g fill="#979197" fillRule="nonzero">
                <path d="M22.206 0C23.24 0 24 .763 24 1.796v20.408C24 23.237 23.24 24 22.206 24H1.788C.838 24 .119 23.353 0 22.397c.002-.075.008-.15.008-.223V1.826c0-.075-.006-.15-.008-.223C.122.64.841 0 1.81 0h20.396zM7.242 9.103c-.959.01-1.916.011-2.875 0-.184-.001-.229.046-.229.233.009 1.722.005 3.442.005 5.164v2.581l-.005 2.58c-.002.157.037.203.194.201.966-.007 1.93-.009 2.896 0 .172.002.22-.04.22-.218-.005-3.443-.003-6.885 0-10.327 0-.164-.04-.215-.206-.214zm9.259-.827c-1.006-.01-1.869.337-2.6 1.036-.24.232-.44.502-.614.792-.02-.007-.038-.014-.054-.024 0-.435-.007-.868.004-1.3.003-.169-.036-.228-.214-.226-.955.009-1.912.007-2.87 0-.164 0-.222.037-.222.218.005 3.625.003 7.252 0 10.877 0 .161.036.213.203.211 1.006-.007 2.013-.01 3.021 0 .178.002.23-.042.23-.23-.007-1.824-.003-3.647-.005-5.471 0-.304.014-.603.05-.905.14-1.16.722-1.784 1.777-1.89 1.055-.105 1.703.376 1.93 1.43.083.393.109.791.11 1.189.006 1.884.006 3.766-.001 5.65-.002.185.047.23.226.23.992-.01 1.985-.012 2.978 0 .192 0 .24-.05.24-.245-.006-1.964.001-3.929 0-5.892-.003-.835-.037-1.668-.22-2.485-.243-1.087-.721-2.006-1.753-2.525-.7-.351-1.45-.433-2.216-.44zM5.364 3.31c-1.125.004-2.055.945-2.054 2.076.003 1.125.94 2.062 2.065 2.062 1.142.002 2.073-.931 2.073-2.075 0-1.137-.939-2.066-2.084-2.063z" transform="translate(-448 -804) translate(412 804) translate(36)"/>
            </g>
        </svg>
    );
}

export default LinkedinIcon;
