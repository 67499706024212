import React from "react";

import "./card.css";

interface Props {
    image: string;
    children: JSX.Element;
}

const Card = ({ image, children }: Props) => (
    <div className="card noSelect">
        <div className="cardImageContainer">
            <div className="cardMainImage" style={{ backgroundImage: `url(${image})` }}/>
        </div>
        <div className="info">
            {children}
        </div>
    </div>
);

export default Card;
